import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import BottomTab from "../../components/BottomTab";
import quizImg from "../../assets/images/quiz.png";
import axios from "axios";

const QuizList = () => {
  const navigate = useNavigate();
  const activeTab = "Home";
  const [quizzes, setQuizzes] = useState([]);
  const [passedQuizzes, setPassedQuizzes] = useState([]);
  const [telegramId, setTelegramId] = useState("");

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("userData"))?._id;
    setTelegramId(JSON.parse(localStorage.getItem("userData"))?.telegramId);
    if (!userId) {
      console.error("Kullanıcı ID'si bulunamadı.");
      return;
    }
    // Kullanıcı bilgilerini çek ve geçmişte tamamlanan quizzleri al
    const fetchUserData = async () => {
      try {
        const userResponse = await axios.get(
          `https://jiicogame.com/api/v1/user/getUserById/${userId}`
        );
        if (userResponse.data.success) {
          const userPassedQuizzes = userResponse.data.data.passedQuizes.map(
            (quiz) => quiz.quizId // Geçilen quizzlerin ID'sini al
          );
          setPassedQuizzes(userPassedQuizzes);
        }
      } catch (error) {
        console.error("Kullanıcı bilgileri alınamadı.", error);
      }
    };
    // Tüm quizleri çek
    const fetchQuizzes = async () => {
      try {
        const response = await axios.get(
          "https://jiicogame.com/api/v1/quiz/getAllQuizzes"
        );
        if (response.data.success) {
          setQuizzes(response.data.data); // API'den gelen quizleri state'e kaydediyoruz
        }
      } catch (error) {
        console.error("Failed to fetch quizzes", error);
      }
    };
    // API isteklerini yap
    fetchUserData();
    fetchQuizzes();
  }, []);

  // Kullanıcının geçmişte geçtiği quizzleri filtrele
  const filteredQuizzes = quizzes.filter(
    (quiz) => !passedQuizzes.includes(quiz._id) // Geçmişte geçilen quizzleri hariç tut
  );

  return (
    <div
      className="min-h-screen text-white font-museo"
      style={{
        background:
          "linear-gradient(180deg, rgba(8, 39, 29, 0.56) 0%, rgba(0, 119, 79, 0.56) 50%, rgba(18, 46, 37, 0.56) 100%), #000000",
        backgroundBlendMode: "difference",
      }}
    >
      {/* Header */}
      <div className="flex justify-between items-center p-4">
        <div className="w-6 h-6" onClick={() => navigate(-1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 512 512"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="48"
              d="M244 400L100 256l144-144M120 256h292"
            />
          </svg>
        </div>
        <span className="text-xs">{telegramId}</span>
      </div>
      {/* Header */}

      <div className="mt-8 px-4">
        <h1 className="text-4xl font-bold text-white">Quizzes</h1>
        <p className="text-sm text-gray-300 mt-2">
          Earn more with invited referral system, your friend and you will
          receive bonuses.
        </p>
      </div>

      <div className="container mx-auto grid grid-cols-2 gap-4 mt-8 px-4">
        {filteredQuizzes.length > 0 ? (
          filteredQuizzes.map((quiz, index) => {
            const isEven = index % 2 === 0;
            return (
              <div
                key={quiz._id}
                className={`${
                  isEven ? "bg-[#DBFF00] text-black" : "bg-[#1A1E00] text-white"
                } border border-[#D6F903] rounded-[10px] h-[75.596px] flex items-center justify-between px-4 cursor-pointer`}
                onClick={() => navigate(`/quiz/${quiz._id}`)} // Her quize tıklandığında quiz id'siyle yönlendirme yapıyoruz
              >
                <div className="flex items-center">
                  <img
                    src={quizImg}
                    alt="Quiz"
                    className="w-10 h-10 flex-shrink-0"
                    style={{ minWidth: "40px", minHeight: "40px" }}
                  />
                  <div className="ml-4">
                    <span className="block text-sm font-semibold truncate w-[100px]">
                      {quiz.quizName}
                    </span>
                    <div className="flex items-center mt-1 justify-between">
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 128 128"
                        >
                          <circle cx="64" cy="66.58" r="57.36" fill="#d68f30" />
                          <path
                            fill="#bc6f00"
                            d="M10.54 81.48v5.86c.6 1.55 1.27 3.08 2 4.56V81.48zm6.54 11.03v7.04c.8 1.13 1.63 2.24 2.5 3.31V92.51zm8.66 9.2v7.59c.97.87 1.97 1.7 3 2.5v-10.09zm10.66 6.99v8.16c.98.54 1.98 1.05 3 1.54v-9.7zm12.09 4.45v8.64c.99.28 1.99.53 3 .76v-9.4zm14.15 1.54v9.21c.45.01.9.03 1.36.03c.55 0 1.1-.03 1.64-.04v-9.2zm14.15-1.61v9.4c1.01-.23 2.01-.49 3-.77v-8.63zm12.06-4.51v9.71c1.02-.49 2.02-1.02 3-1.57v-8.14zm10.63-7.05v10.1a56 56 0 0 0 3-2.52v-7.58zm11.61-9.23h-2.5v10.35c.87-1.08 1.71-2.19 2.5-3.33zm6.5-11.07h-2v10.44c.73-1.51 1.4-3.06 2-4.63z"
                          />
                          <circle cx="64" cy="61.42" r="57.36" fill="#fff176" />
                          <circle cx="64" cy="61.42" r="52.25" fill="#f2bc1a" />
                        </svg>
                        <span className="text-xs font-bold ml-1">
                          +{quiz.reward}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-white text-center">Tüm testleri tamamladınız.</p>
        )}
      </div>

      <BottomTab activeTab={activeTab} />
    </div>
  );
};

export default QuizList;
