// src/pages/Home.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import BottomTab from "../../components/BottomTab";
import token from "../../assets/images/token.png";
import youtube from "../../assets/images/youtube.png";
import twitter from "../../assets/images/twitter.png";
import medium from "../../assets/images/medium.png";
import instagram from "../../assets/images/instagram.png";

const Airdrop = () => {
  const navigate = useNavigate();
  const activeTab = "Airdrop";

  return (
    <div
      className="min-h-screen text-white font-museo"
      style={{
        background:
          "linear-gradient(180deg, rgba(8, 39, 29, 0.56) 0%, rgba(0, 119, 79, 0.56) 50%, rgba(18, 46, 37, 0.56) 100%), #000000",
        backgroundBlendMode: "difference",
      }}
    >
      {/* Header */}
      <Header telegramId="#TelegramID" />
      {/* Header */}

      {/* Coin Image and Airdrop Info */}
      <div className="flex flex-col items-center justify-center mt-16">
        <div className="w-32 h-32 mb-4">
          <img src={token} alt="Token" />
        </div>
        <h1 className="text-4xl font-normal">Air Drop</h1>
        <p className="text-sm text-gray-300 mt-2">
          Stay awake, airdrop is coming soon!
        </p>
      </div>
      {/* Coin Image and Airdrop Info */}

      {/* Connect Wallet Button */}
      <div className="flex justify-center mt-16">
        <button className="flex items-center bg-[#D8FB02] text-black py-2 px-6 rounded-md active:scale-95 transition-transform duration-150">
          <div className="flex justify-center items-center w-6 h-6 mr-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <g fill="black" fillRule="evenodd" clipRule="evenodd">
                <path d="M21.1 8.004q-.085-.005-.181-.004h-2.525c-2.068 0-3.837 1.628-3.837 3.75s1.77 3.75 3.837 3.75h2.525q.096.001.182-.004a1.755 1.755 0 0 0 1.645-1.628c.004-.06.004-.125.004-.185V9.817c0-.06 0-.125-.004-.185a1.755 1.755 0 0 0-1.645-1.628m-2.928 4.746c.532 0 .963-.448.963-1s-.431-1-.963-1c-.533 0-.964.448-.964 1s.431 1 .964 1" />
                <path d="M20.918 17a.22.22 0 0 1 .221.278c-.2.712-.519 1.32-1.03 1.83c-.749.75-1.698 1.081-2.87 1.239c-1.14.153-2.595.153-4.433.153h-2.112c-1.838 0-3.294 0-4.433-.153c-1.172-.158-2.121-.49-2.87-1.238c-.748-.749-1.08-1.698-1.238-2.87C2 15.099 2 13.644 2 11.806v-.112C2 9.856 2 8.4 2.153 7.26c.158-1.172.49-2.121 1.238-2.87c.749-.748 1.698-1.08 2.87-1.238C7.401 3 8.856 3 10.694 3h2.112c1.838 0 3.294 0 4.433.153c1.172.158 2.121.49 2.87 1.238c.511.512.83 1.119 1.03 1.831a.22.22 0 0 1-.221.278h-2.524c-2.837 0-5.337 2.24-5.337 5.25s2.5 5.25 5.337 5.25zM5.75 7a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5z" />
              </g>
            </svg>
          </div>
          <span>Connect wallet</span>
        </button>
      </div>
      {/* Connect Wallet Button */}

      {/* Social Media Follow Section */}
      <div className="text-center mt-24 mx-4">
        <p className="text-white mb-4">
          Yeni güncellemeleri kaçırmamak için bizi sosyal medyada takip edin
        </p>
        <div className="flex justify-center space-x-8 mt-6">
          {/* YouTube Icon */}
          <button className="w-[30px] h-[30px] transition-transform transform active:scale-90">
            <img
              src={youtube}
              alt="YouTube"
              className="w-full h-full object-cover"
            />
          </button>
          {/* Twitter Icon */}
          <button className="w-[30px] h-[30px] transition-transform transform active:scale-90">
            <img
              src={twitter}
              alt="Twitter"
              className="w-full h-full object-cover"
            />
          </button>
          {/* Medium Icon */}
          <button className="w-[30px] h-[30px] transition-transform transform active:scale-90">
            <img
              src={medium}
              alt="Medium"
              className="w-full h-full object-cover"
            />
          </button>
          {/* Instagram Icon */}
          <button className="w-[30px] h-[30px] transition-transform transform active:scale-90">
            <img
              src={instagram}
              alt="Instagram"
              className="w-full h-full object-cover"
            />
          </button>
        </div>
      </div>

      {/* Social Media Follow Section */}

      <BottomTab activeTab={activeTab} />
    </div>
  );
};

export default Airdrop;
