import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BottomTab from "../../components/BottomTab";

const Settings = () => {
  const navigate = useNavigate();
  const activeTab = "Home";

  // LocalStorage'dan haptic ayarını kontrol ediyoruz
  const [hapticEnabled, setHapticEnabled] = useState(
    JSON.parse(localStorage.getItem("userSettings"))?.hapticEnabled || false
  );

  // Haptic feedback ayarını toggle eden fonksiyon
  const toggleHapticFeedback = () => {
    const newHapticState = !hapticEnabled;
    setHapticEnabled(newHapticState);

    // LocalStorage'e kaydediyoruz
    const userSettings = {
      hapticEnabled: newHapticState,
    };
    localStorage.setItem("userSettings", JSON.stringify(userSettings));
  };

  useEffect(() => {
    // Ayarları localStorage'dan kontrol ediyoruz
    const savedSettings = JSON.parse(localStorage.getItem("userSettings"));
    if (savedSettings) {
      setHapticEnabled(savedSettings.hapticEnabled);
    }
  }, []);

  return (
    <div
      className="min-h-screen text-white font-museo"
      style={{
        background:
          "linear-gradient(180deg, rgba(8, 39, 29, 0.56) 0%, rgba(0, 119, 79, 0.56) 50%, rgba(18, 46, 37, 0.56) 100%), #000000",
        backgroundBlendMode: "difference",
      }}
    >
      {/* Header */}
      <div className="flex justify-between items-center p-4">
        <div className="w-6 h-6" onClick={() => navigate(-1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 512 512"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="48"
              d="M244 400L100 256l144-144M120 256h292"
            />
          </svg>
        </div>
        <span className="text-xs">telegramId</span>
      </div>
      {/* Header */}

      <div className="px-4 mt-4">
        <h1 className="text-4xl font-bold text-white">Settings</h1>
      </div>

      <div className="px-4 mt-4 space-y-4">
        {/* Haptic Feedback Switch */}
        <div className="w-full h-16 flex justify-between items-center bg-[#1A1E00] border border-[#D6F903] rounded-[10px] p-4 text-left">
          <span className="text-sm text-white">Haptic Feedback</span>
          {/* Toggle Switch */}
          <div>
            <label
              htmlFor="toggle3"
              className="flex cursor-pointer select-none items-center"
            >
              <div className="relative">
                <input
                  type="checkbox"
                  id="toggle3"
                  className="sr-only"
                  checked={hapticEnabled} // Checkbox durumunu yönetiyoruz
                  onChange={toggleHapticFeedback} // Fonksiyon çalıştırılıyor
                />
                <div className="block h-8 w-14 rounded-full bg-[#5A616B] dark:bg-[#5A616B]"></div>
                <div
                  className={`dot absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full bg-white transition ${
                    hapticEnabled
                      ? "!right-1 !translate-x-full !bg-[#D6F903]"
                      : "bg-white"
                  }`}
                >
                  <span className={`hidden ${hapticEnabled && "!block"}`}>
                    <svg
                      className="fill-white"
                      width="11"
                      height="8"
                      viewBox="0 0 11 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                        fill=""
                        stroke=""
                        strokeWidth="0.4"
                      ></path>
                    </svg>
                  </span>
                  <span className={`${hapticEnabled ? "hidden" : "block"}`}>
                    <svg
                      className="h-4 w-4 stroke-current"
                      fill="none"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
            </label>
          </div>
        </div>

        {/* Select Language Button */}
        <button className="w-full h-16 flex justify-between items-center bg-[#1A1E00] border border-[#D6F903] rounded-[10px] p-4 text-left transition-transform transform active:scale-95">
          <div>
            <span className="block text-sm text-white">Select language</span>
            <span className="block text-xs text-gray-400">English</span>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="white"
                d="M8.59 16.58L13.17 12 8.59 7.41 10 6l6 6-6 6z"
              />
            </svg>
          </div>
        </button>

        {/* Delete Account Button */}
        <button className="w-full h-16 flex justify-between items-center bg-[#1A1E00] border border-[#D6F903] rounded-[10px] p-4 text-left transition-transform transform active:scale-95">
          <span className="text-sm text-white">Delete account</span>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="white"
                d="M8.59 16.58L13.17 12 8.59 7.41 10 6l6 6-6 6z"
              />
            </svg>
          </div>
        </button>

        {/* Privacy Policy Button */}
        <button className="w-full h-16 flex justify-between items-center bg-[#1A1E00] border border-[#D6F903] rounded-[10px] p-4 text-left transition-transform transform active:scale-95">
          <span className="text-sm text-white">Privacy policy</span>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="white"
                d="M8.59 16.58L13.17 12 8.59 7.41 10 6l6 6-6 6z"
              />
            </svg>
          </div>
        </button>
      </div>

      <BottomTab activeTab={activeTab} />
    </div>
  );
};

export default Settings;
