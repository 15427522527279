import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BottomTab from "../../components/BottomTab";

const QuizResult = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Query parametrelerini parse ediyoruz
  const queryParams = new URLSearchParams(location.search);
  const quizName = queryParams.get("quizName");
  const score = queryParams.get("score");
  const isPassed = queryParams.get("isPassed") === "true"; // Boolean çevrimi
  const timeSpent = queryParams.get("timeSpent");

  return (
    <div
      className="min-h-screen flex flex-col text-white font-museo"
      style={{
        background:
          "linear-gradient(180deg, rgba(8, 39, 29, 0.56) 0%, rgba(0, 119, 79, 0.56) 50%, rgba(18, 46, 37, 0.56) 100%), #000000",
        backgroundBlendMode: "difference",
      }}
    >
      {/* Header */}
      <div className="p-4">
        <h1 className="text-4xl font-bold text-left">Quiz Sonuçları</h1>
      </div>

      {/* Quiz Result İçerik */}
      <div className="flex-1 flex flex-col items-center">
        <div className="grid grid-cols-2 gap-4 px-4 w-full">
          <div className="bg-[#1A1E00] border border-[#D5F803] rounded-[10px] p-6 text-center">
            <p className="text-xl font-semibold">Quiz Name</p>
            <p className="text-lg">{quizName}</p>
          </div>

          <div className="bg-[#1A1E00] border border-[#D5F803] rounded-[10px] p-6 text-center">
            <p className="text-xl font-semibold">Puanınız</p>
            <p className="text-lg">{score}</p>
          </div>

          <div className="bg-[#1A1E00] border border-[#D5F803] rounded-[10px] p-6 text-center">
            <p className="text-xl font-semibold">Geçen Süre</p>
            <p className="text-lg">{timeSpent} saniye</p>
          </div>

          <div className="bg-[#1A1E00] border border-[#D5F803] rounded-[10px] p-6 text-center">
            <p className="text-xl font-semibold">Sonuç</p>
            <p className="text-lg">
              {isPassed
                ? "Tebrikler, Quiz'i Geçtiniz!"
                : "Maalesef, Geçemediniz!"}
            </p>
          </div>
        </div>

        <div className="mt-6 w-full px-4">
          <button
            onClick={() => navigate("/")}
            className="w-full py-3 bg-green-500 text-white font-bold rounded-[10px] transition-all duration-200 hover:bg-green-600"
          >
            Ana Sayfaya Dön
          </button>
        </div>
      </div>

      {/* BottomTab */}
      <div className="fixed bottom-0 left-0 right-0">
        <BottomTab activeTab="Home" />
      </div>
    </div>
  );
};

export default QuizResult;
