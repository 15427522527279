import React, { useState, useEffect, useRef } from "react";
import Bird from "../../assets/images/deneme.png";
import { useNavigate } from "react-router-dom";
import BottomTab from "../../components/BottomTab";
import skin from "../../assets/images/skin.png";
import axios from "axios";
import Loader from "../../components/Loader";

const Home = () => {
  const navigate = useNavigate();
  const activeTab = "Home";
  const [clicks, setClicks] = useState([]);
  const [points, setPoints] = useState(0);
  const pointsToAdd = 8;
  const [userData, setUserData] = useState(null);
  const ws = useRef(null);
  const [energy, setEnergy] = useState(6500);
  const [telegramId, setTelegramId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    // Eğer kullanıcı zaten login olduysa, yeniden giriş yapmayı engelle
    if (storedUserData && loggedIn) {
      setUserData(JSON.parse(storedUserData));
      setPoints(JSON.parse(storedUserData).balance);
      setTelegramId(JSON.parse(storedUserData).telegramId);
      setLoggedIn(true); // Kullanıcı giriş yaptı işaretle
      return;
    }
    // Telegram WebApp kontrol et
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.expand();
      const telegram = window.Telegram.WebApp;
      const user = telegram.initDataUnsafe?.user;
      if (!user) {
        alert("No Telegram user found.");
        return;
      }
      setTelegramId(user.id);
      // Eğer kullanıcı localStorage'da değilse login/register işlemi yap
      setLoading(true);
      axios
        .post("https://jiicogame.com/api/v1/user/loginWithTelegram", {
          userName: user.username,
        })
        .then((response) => {
          if (response.data.success) {
            setUserData(response.data.data.user);
            setPoints(response.data.data.user.balance);
            localStorage.setItem(
              "userData",
              JSON.stringify(response.data.data.user)
            );
            setLoggedIn(true); // Kullanıcı giriş yaptı işaretle
          }
        })
        .catch(() => {
          // Eğer login başarısız olursa, register işlemi yap
          axios
            .post("https://jiicogame.com/api/v1/user/registerWithTelegram", {
              userName: user.username,
              telegramId: user.id,
              firstName: user.first_name,
              lastName: user.last_name,
            })
            .then((response) => {
              if (response.data.success) {
                setUserData(response.data.data.user);
                setPoints(response.data.data.user.balance);
                localStorage.setItem(
                  "userData",
                  JSON.stringify(response.data.data.user)
                );
                setLoggedIn(true); // Kullanıcı giriş yaptı işaretle
              }
            });
        })
        .finally(() => {
          setLoading(false);
        });

      telegram.ready();
    }
  }, [loggedIn]); // loggedIn state'i eklendi, bir kez login olduysa yeniden kontrol yapılmaz

  useEffect(() => {
    if (!loggedIn || !userData) return;
    ws.current = new WebSocket("wss://jiicogame.com/api/v1/socket");
    ws.current.onopen = () => {
      console.log("WebSocket connection established");
    };
    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.balance !== undefined) {
        setPoints(data.balance); // Gelen bakiye bilgisi ile güncelleme
      } else if (data.error) {
        console.error("WebSocket error:", data.error);
      }
    };
    ws.current.onclose = () => {
      console.log("WebSocket connection closed");
    };
    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
    return () => {
      ws.current.close();
    };
  }, [loggedIn, userData]);

  // Enerji seviyesini her saniye artıran efekt
  useEffect(() => {
    const energyRegenInterval = setInterval(() => {
      setEnergy((prevEnergy) => Math.min(prevEnergy + 2, 6500));
    }, 3000); // Her 3 saniyede 2 enerji ekle
    return () => clearInterval(energyRegenInterval); // Temizleme
  }, []);

  const handleTouchStart = (event) => {
    const touches = event.touches;

    const newClicks = [];
    let addedPoints = 0;

    // LocalStorage'dan ayarı alıyoruz
    const userSettings = JSON.parse(localStorage.getItem("userSettings"));

    // Eğer Haptic Feedback etkinse, titreşim uyguluyoruz
    if (
      userSettings?.hapticEnabled &&
      window.Telegram.WebApp &&
      window.Telegram.WebApp.HapticFeedback
    ) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred("light");
    }

    for (let i = 0; i < touches.length; i++) {
      const touch = touches[i];
      const buttonRect = event.currentTarget.getBoundingClientRect();
      const clickX = touch.clientX - buttonRect.left;
      const clickY = touch.clientY - buttonRect.top;

      const card = event.currentTarget;
      const x = touch.clientX - buttonRect.left - buttonRect.width / 2;
      const y = touch.clientY - buttonRect.top - buttonRect.height / 2;
      card.style.transform = `perspective(1000px) rotateX(${
        -y / 10
      }deg) rotateY(${x / 10}deg)`;

      newClicks.push({
        id: Date.now() + i,
        x: clickX,
        y: clickY,
        message: `+${pointsToAdd}`,
      });

      addedPoints += pointsToAdd;

      setTimeout(() => {
        card.style.transform = "";
      }, 100);
    }

    setClicks((prevClicks) => {
      const updatedClicks = [...prevClicks, ...newClicks];
      if (updatedClicks.length > 10) {
        updatedClicks.splice(0, updatedClicks.length - 10); // Fazla tıklamaları kaldır
      }
      return updatedClicks;
    });

    setPoints(points + addedPoints); // Puanları ekle

    setEnergy((prevEnergy) => Math.max(prevEnergy - addedPoints, 0)); // Enerji azaltma

    // WebSocket üzerinden sunucuya tıklama bilgilerini gönder
    if (ws.current && userData) {
      const data = {
        userId: userData._id,
        pointsToAdd: addedPoints,
      };
      ws.current.send(JSON.stringify(data)); // Tıklama bilgilerini gönder
    }

    setTimeout(() => {
      setClicks((prevClicks) =>
        prevClicks.filter(
          (click) => !newClicks.some((newClick) => newClick.id === click.id)
        )
      );
    }, 1000);
  };

  const handleAnimationEnd = (id) => {
    // Tıklama animasyonu bittikten sonra ilgili tıklama efektini kaldır
    setClicks((prevClicks) => prevClicks.filter((click) => click.id !== id));
  };

  const handleBoost = () => {
    // Enerjiyi artır, örneğin +500
    setEnergy((prevEnergy) => Math.min(prevEnergy + 500, 6500)); // 6500'ü aşma
  };

  return (
    <div
      className="h-screen overflow-auto text-white font-museo"
      style={{
        background:
          "linear-gradient(180deg, rgba(8, 39, 29, 0.56) 0%, rgba(0, 119, 79, 0.56) 50%, rgba(18, 46, 37, 0.56) 100%), #000000",
        backgroundBlendMode: "difference",
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* Header */}
          <div className="flex justify-between items-center p-4 font-muse">
            <span className="text-xs font-normal">{telegramId}</span>
            <div
              onClick={() => {
                navigate("/settings");
              }}
              className="w-auto h-auto"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.26368 20L6.86567 16.8C6.65008 16.7167 6.4471 16.6167 6.25672 16.5C6.06634 16.3833 5.8796 16.2583 5.69652 16.125L2.73632 17.375L0 12.625L2.56219 10.675C2.54561 10.5583 2.53731 10.446 2.53731 10.338V9.663C2.53731 9.55433 2.54561 9.44167 2.56219 9.325L0 7.375L2.73632 2.625L5.69652 3.875C5.87894 3.74167 6.06965 3.61667 6.26866 3.5C6.46766 3.38333 6.66667 3.28333 6.86567 3.2L7.26368 0H12.7363L13.1343 3.2C13.3499 3.28333 13.5532 3.38333 13.7443 3.5C13.9353 3.61667 14.1217 3.74167 14.3035 3.875L17.2637 2.625L20 7.375L17.4378 9.325C17.4544 9.44167 17.4627 9.55433 17.4627 9.663V10.337C17.4627 10.4457 17.4461 10.5583 17.4129 10.675L19.9751 12.625L17.2388 17.375L14.3035 16.125C14.1211 16.2583 13.9303 16.3833 13.7313 16.5C13.5323 16.6167 13.3333 16.7167 13.1343 16.8L12.7363 20H7.26368ZM10.0498 13.5C11.0116 13.5 11.8325 13.1583 12.5124 12.475C13.1924 11.7917 13.5323 10.9667 13.5323 10C13.5323 9.03333 13.1924 8.20833 12.5124 7.525C11.8325 6.84167 11.0116 6.5 10.0498 6.5C9.07131 6.5 8.2461 6.84167 7.57413 7.525C6.90216 8.20833 6.5665 9.03333 6.56717 10C6.56783 10.9667 6.90381 11.7917 7.57513 12.475C8.24644 13.1583 9.07131 13.5 10.0498 13.5Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          {/* Header */}

          {/* Quizzes ve Tasks Butonları */}
          <div className="flex justify-between items-center px-4 mt-1">
            <button
              onClick={() => {
                setTimeout(() => {
                  navigate("/task-list");
                }, 100);
              }}
              className="relative text-black rounded-lg text-sm bg-[#D6F903] border border-[#D6F903] w-[92.252px] h-[42px] flex-shrink-0 shadow-[0px_3px_0px_rgba(204,255,0,0.75)] active:scale-95 transition-transform duration-150 ease-in-out active:bg-[#c5e100]"
            >
              <span className="relative font-normal">Tasks</span>
              <span className="absolute top-[10px] right-[10px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 14 14"
                >
                  <path
                    fill="#FF0000"
                    fillRule="evenodd"
                    d="M7.935.655c-.318-.873-1.552-.873-1.87 0L4.622 4.622L.655 6.065c-.873.318-.873 1.552 0 1.87l3.967 1.443l1.443 3.967c.318.873 1.552.873 1.87 0l1.443-3.967l3.967-1.443c.873-.318.873-1.552 0-1.87L9.378 4.622z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </button>

            <button
              onClick={() => {
                setTimeout(() => {
                  navigate("/quiz-list");
                }, 100);
              }}
              className="relative text-[#D7FA02] rounded-lg text-sm border border-[#D7FA02] w-[92.252px] h-[42px] flex-shrink-0 bg-transparent active:scale-95 transition-transform duration-150 ease-in-out active:bg-[#b2e000]"
            >
              <span className="relative font-normal">Quizzes</span>
            </button>
          </div>
          {/* Quizzes ve Tasks Butonları */}

          {/* Coin Balance */}
          <div className="flex justify-center items-center mt-4 text-[#D9FF00]">
            <span className="text-5xl font-bold">
              {points.toLocaleString()}
            </span>
          </div>
          {/* Coin Balance */}

          {/* Triple Section */}
          <div className="flex justify-center items-center space-x-2 mt-4 mx-auto px-4 max-w-[90%]">
            {/* Skins Section */}
            <div
              onClick={() => navigate("/skins")}
              className="flex items-center justify-between w-[126.175px] h-[63.679px] 
          bg-[#1A1E00] border border-[#D5F803] rounded-[10px] p-2 flex-shrink-0 
          active:scale-95 transition-transform duration-150"
            >
              <div>
                <span className="block text-xs text-[#D5F803] font-semibold">
                  Skins
                </span>
                <span className="block text-[10px] text-[#D5F803]">Beebee</span>
              </div>
              <div className="relative w-10 h-10 flex items-center justify-center rounded-full">
                <img
                  src={skin}
                  alt="Skin Icon"
                  className="w-full h-full object-cover rounded-full"
                />
              </div>
            </div>

            {/* Earn per tap Section */}
            <div
            onClick={() => setIsModalOpen(true)} // Modal açma fonksiyonunu burada çağırıyoruz
              className="flex flex-col items-center justify-center w-[126.175px] h-[63.679px] bg-[#1A1E00] border border-[#D5F803] rounded-[10px] p-2 flex-shrink-0 text-[#D5F803] active:scale-95 transition-transform duration-150"
            >
              <span className="block text-xs mb-1">Earn per tap</span>
              <div className="flex items-center">
                <span className="text-xs font-semibold ml-1">
                  +{pointsToAdd}
                </span>
              </div>
            </div>

            {/* Profit per hour Section */}
            <div className="flex flex-col items-center justify-center w-[126.175px] h-[63.679px] bg-[#1A1E00] border border-[#D5F803] rounded-[10px] p-2 flex-shrink-0 text-[#D5F803] active:scale-95 transition-transform duration-150">
              <span className="block text-xs mb-1">Profit per hour</span>
              <div className="flex items-center">
                <span className="text-xs font-semibold ml-1">+228</span>
              </div>
            </div>
          </div>
          {/* Triple Section */}

          {/* Modal */}
          {isModalOpen && (
            <div className="px-4 fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
              <div className="bg-[#1A1E00] border border-[#D5F803] p-6 rounded-lg text-white">
                <h2 className="text-xl mb-4">Boost Earn Per Tap</h2>
                <p>
                  500 Coin harcayarak tıklama başına kazancı artırabilirsiniz.
                </p>
                <button className="mt-4 p-2 bg-[#D6F903] text-black rounded-lg">
                  500 Coin ile Boost
                </button>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="ml-4 mt-4 p-2 bg-gray-600 text-white rounded-lg"
                >
                  Kapat
                </button>
              </div>
            </div>
          )}
          {/* Modal */}

          {/* Clickable Circle Area */}
          <div
            className="relative mx-auto mt-6"
            style={{ width: "300px", height: "300px" }}
            onTouchStart={handleTouchStart}
          >
            {/* Dıştaki Daire */}
            <div
              className="absolute rounded-full"
              style={{
                width: "300px",
                height: "300px",
                background:
                  "linear-gradient(181deg, #DBFF00 1.2%, #61742B 65.02%)",
                boxShadow: "0 0 10px 2px rgba(255, 255, 255, 0.5)",
              }}
            />
            {/* İçerdeki Daire */}
            <div
              className="absolute left-[15px] top-[15px] rounded-full"
              style={{
                width: "270px",
                height: "270px",
                background: "linear-gradient(180deg, #1F280D 0%, #32481C 98%)",
              }}
            />
            <div className="absolute inset-0 m-auto w-48 h-48 object-cover">
              <img
                src={Bird}
                alt="Bird"
                className="w-full h-full rounded-full"
              />
            </div>

            <style>
              {`
            @keyframes float {
              0% {
                transform: translateY(0);
                opacity: 1;
              }
              100% {
                transform: translateY(-50px); /* Yükseklik daha fazla arttı */
                opacity: 0;
              }
            }
          `}
            </style>

            {/* +1 animasyonları */}
            {clicks.map((click) => (
              <div
                key={click.id}
                className="absolute text-white text-lg font-bold pointer-events-none"
                style={{
                  top: `${click.y - 42}px`,
                  left: `${click.x - 28}px`,
                  transform: "translate(-50%, -50%)",
                  animation: `float 1s ease-out`,
                }}
                onAnimationEnd={() => handleAnimationEnd(click.id)}
              >
                {click.message}
              </div>
            ))}
          </div>
          {/* Clickable Circle Area */}

          {/* Level Progress */}
          <div className="mx-4 mt-2">
            <div className="flex justify-between text-xs text-gray-300">
              <span>Hero</span>
              <span>Level 7</span>
            </div>
            <div className="bg-gray-700 h-3 rounded-full mt-1">
              <div
                className="bg-[#BBF556] h-3 rounded-full"
                style={{ width: "100%" }}
              ></div>
            </div>
          </div>
          {/* Level Progress */}

          {/* Energy And Boost */}
          <div className="flex justify-between items-center mx-4 mt-4 mb-24">
            {/* Energy Section */}
            <div className="flex items-center space-x-2">
              <div className="w-6 h-6 bg-[#BBF556] rounded-full flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="black"
                    d="m213.85 125.46l-112 120a8 8 0 0 1-13.69-7l14.66-73.33l-57.63-21.64a8 8 0 0 1-3-13l112-120a8 8 0 0 1 13.69 7l-14.7 73.41l57.63 21.61a8 8 0 0 1 3 12.95Z"
                  />
                </svg>
              </div>
              {/* Dinamik enerji göstergesi */}
              <span className="text-white text-sm">
                {energy.toLocaleString()} / 6500
              </span>
            </div>

            {/* Boost Button */}
            <button
              onClick={handleBoost} // Boost fonksiyonunu burada çağırıyoruz
              className="relative flex items-center justify-center space-x-2 text-black rounded-lg text-sm bg-[#D6F903] border border-[#D6F903] w-[92.252px] h-[42px] flex-shrink-0 shadow-[0px_3px_0px_rgba(204,255,0,0.75)] transition-transform duration-150 ease-in-out active:scale-95"
            >
              <span>Boost</span>
            </button>
          </div>
          {/* Energy And Boost */}
        </>
      )}

      <BottomTab activeTab={activeTab} />
    </div>
  );
};

export default Home;
