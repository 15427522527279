import React from "react";
import Lottie from "react-lottie"; // react-lottie'den Lottie bileşeni
import loaderAnimation from "../assets/animations/loader.json"; // Lottie dosyanızın yolu

const Loader = () => {
  // Lottie animasyonu için ayarlar
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation, // Lottie animasyon dosyası
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex justify-center items-center h-full w-full">
      <Lottie options={defaultOptions} height={150} width={150} />
    </div>
  );
};

export default Loader;
