import React from "react";
import { useNavigate } from "react-router-dom";
import BottomTab from "../../components/BottomTab";

const Skins = () => {
  const navigate = useNavigate();
  const activeTab = "Income";

  return (
    <div
      className="min-h-screen text-white font-museo"
      style={{
        background:
          "linear-gradient(180deg, rgba(8, 39, 29, 0.56) 0%, rgba(0, 119, 79, 0.56) 50%, rgba(18, 46, 37, 0.56) 100%), #000000",
        backgroundBlendMode: "difference",
      }}
    >
      {/* Header */}
      <div className="flex justify-between items-center p-4">
        <div className="w-6 h-6" onClick={() => navigate(-1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 512 512"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="48"
              d="M244 400L100 256l144-144M120 256h292"
            />
          </svg>
        </div>
        <span className="text-xs">telegramId</span>
      </div>
      {/* Header */}

      <div className="px-4 mt-4">
        <h1 className="text-4xl font-normal text-white">Skins</h1>
      </div>

      <BottomTab activeTab={activeTab} />
    </div>
  );
};

export default Skins;
