import React, { useState, useEffect } from "react";
import BottomTab from "../../components/BottomTab";
import { useNavigate } from "react-router-dom";
import big_token from "../../assets/images/big_token.png";
import telegramIcon from "../../assets/images/telegram_icon.png";
import axios from "axios";

const TaskList = () => {
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskStatuses, setTaskStatuses] = useState(
    JSON.parse(localStorage.getItem("taskStatuses")) || {}
  );
  const activeTab = "Home";
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("userData"));

  const openOffcanvas = (task) => {
    setSelectedTask(task);
    setIsOffcanvasOpen(true);
  };

  const closeOffcanvas = () => setIsOffcanvasOpen(false);

  // Görevleri API'den çek
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(
          "https://jiicogame.com/api/v1/task/getAllTasks"
        );
        if (response.data.success) {
          setTasks(response.data.data);
        } else {
          alert("Görevler alınamadı: " + response.data.message);
        }
      } catch (error) {
        alert("API isteği hatası: " + error.message);
      }
    };

    fetchTasks();
  }, []);

  // Kullanıcının tamamladığı görevlerin kontrolü
  const isTaskCompleted = (taskId) => {
    return user.completedTasks.some(
      (completedTask) =>
        completedTask.taskId === taskId && completedTask.status === "completed"
    );
  };

  // Görev başlatma fonksiyonu
  const startTask = async (task) => {
    try {
      const response = await axios.put(
        `https://jiicogame.com/api/v1/task/startTask/${task._id}/${user._id}`
      );

      if (response.data.success) {
        // Görevi başlattıktan sonra durumu güncelle ve localStorage'a kaydet
        const updatedStatuses = {
          ...taskStatuses,
          [task._id]: "checking",
        };
        setTaskStatuses(updatedStatuses);
        localStorage.setItem("taskStatuses", JSON.stringify(updatedStatuses));

        closeOffcanvas();
      } else {
        alert("Görev başlatılamadı: " + response.data.message);
      }
    } catch (error) {
      alert("API isteği hatası: " + error.message);
    }
  };

  // Görev kontrol etme fonksiyonu
  const checkTask = async (task) => {
    try {
      const response = await axios.put(
        `https://jiicogame.com/api/v1/task/checkTask/${task._id}/${user._id}`
      );

      if (response.data.success) {
        // Görev başarılı tamamlandıysa durumu güncelle
        const updatedStatuses = {
          ...taskStatuses,
          [task._id]: "completed",
        };
        setTaskStatuses(updatedStatuses);
        localStorage.setItem("taskStatuses", JSON.stringify(updatedStatuses));

        closeOffcanvas();
      } else {
        alert("Görev kontrol edilemedi: " + response.data.message);
      }
    } catch (error) {
      alert("API isteği hatası: " + error.message);
    }
  };

  return (
    <div
      className="min-h-screen text-white font-museo"
      style={{
        background:
          "linear-gradient(180deg, rgba(8, 39, 29, 0.56) 0%, rgba(0, 119, 79, 0.56) 50%, rgba(18, 46, 37, 0.56) 100%), #000000",
        backgroundBlendMode: "difference",
      }}
    >
      {/* Header */}
      <div className="flex justify-between items-center p-4">
        <div className="w-6 h-6" onClick={() => navigate(-1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 512 512"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="48"
              d="M244 400L100 256l144-144M120 256h292"
            />
          </svg>
        </div>
        <span className="text-xs">{user?.telegramId}</span>
      </div>

      {/* Title */}
      <div className="flex flex-col items-center mt-8">
        <div className="w-24 h-24 mb-4">
          <img src={big_token} alt="Token" />
        </div>
        <div className="text-center text-white text-[32px] font-normal">
          Complete tasks
          <br />
          for earn more coins
        </div>
      </div>

      {/* Task List */}
      <div className="px-4 mt-8">
        <h2 className="text-white text-md font-medium mb-3">Task list</h2>
        <div className="space-y-3">
          {tasks
            .filter((task) => !isTaskCompleted(task._id))
            .map((task) => (
              <div
                key={task._id}
                className="flex items-center justify-between p-3 bg-[#1A1E00] border border-[#D6F903] rounded-lg cursor-pointer"
                onClick={() => openOffcanvas(task)}
              >
                <div className="flex items-center">
                  <div className="mr-3 w-7 h-7">
                    <img src={telegramIcon} alt="Telegram Icon" />
                  </div>
                  <div>
                    <span className="block text-white text-sm font-medium">
                      {task.taskName}
                    </span>
                    <div className="flex items-center mt-1">
                      <div className="mr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 128 128"
                        >
                          <circle cx="64" cy="66.58" r="57.36" fill="#d68f30" />
                          <circle cx="64" cy="61.42" r="57.36" fill="#fff176" />
                        </svg>
                      </div>
                      <span className="text-[#D6F903] text-xs font-bold">
                        +{task.reward}
                      </span>
                      {taskStatuses[task._id] === "checking" && (
                        <span className="ml-2 text-gray-300">(checking)</span>
                      )}
                      {taskStatuses[task._id] === "completed" && (
                        <span className="ml-2 text-green-300">(completed)</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <BottomTab activeTab={activeTab} />

      {/* Offcanvas */}
      {isOffcanvasOpen && selectedTask && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-end sm:items-center"
          onClick={closeOffcanvas}
        >
          <div
            className="bg-[#192D1F] border border-[#D8FB02] rounded-t-[30px] sm:rounded-[30px] w-full sm:w-[375px] h-2/3 p-6 z-50 relative"
            style={{
              borderRadius: "30px 30px 0px 0px",
              border: "1px solid #D8FB02",
              background: "#192D1F",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-col items-center">
              <div className="w-12 h-12 mb-2">
                <img src={telegramIcon} alt="Telegram Icon" />
              </div>
              <h2 className="text-white text-lg font-bold mb-2">
                {selectedTask.taskName}
              </h2>
              <p className="text-white text-[16px] mb-4">
                {selectedTask.taskDescription}
              </p>

              {taskStatuses[selectedTask._id] === "checking" ? (
                <button
                  className="w-full mt-4 py-4 text-center text-black text-sm rounded-[10px] bg-[#D6F903] border border-[#D6F903] h-auto flex-shrink-0 shadow-[0px_3px_0px_0px_#B7D500] active:scale-95 transition-transform duration-150 ease-in-out"
                  onClick={() => checkTask(selectedTask)}
                >
                  <h2 className=" text-lg font-semibold">Kontrol Et</h2>
                </button>
              ) : (
                <button
                  className="w-full mt-4 py-4 text-center text-black text-sm rounded-[10px] bg-[#D6F903] border border-[#D6F903] h-auto flex-shrink-0 shadow-[0px_3px_0px_0px_#B7D500] active:scale-95 transition-transform duration-150 ease-in-out"
                  onClick={() => startTask(selectedTask)}
                >
                  <h2 className=" text-lg font-semibold">
                    {selectedTask.buttonName}
                  </h2>
                </button>
              )}

              <div className="flex items-center mt-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 128 128"
                >
                  <circle cx="64" cy="66.58" r="57.36" fill="#d68f30" />
                  <circle cx="64" cy="61.42" r="57.36" fill="#fff176" />
                </svg>
                <span className="text-[#D6F903] text-base font-bold ml-2">
                  +{selectedTask.reward}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskList;
