import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BottomTab from "../../components/BottomTab";

const Friends = () => {
  const [isListVisible, setIsListVisible] = useState(false);
  const [invitedFriends, setInvitedFriends] = useState([]);
  const [telegramId, setTelegramId] = useState(null);
  const navigate = useNavigate();

  const toggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setTelegramId(userData.telegramId);
      fetchInvitedFriends(userData.telegramId);
    }
  }, []);

  const fetchInvitedFriends = async (telegramId) => {
    try {
      const response = await axios.get(
        `https://jiicogame.com/api/v1/user/getInvitedFriends/${telegramId}`
      );
      if (response.data) {
        setInvitedFriends(response.data.data); // API'den gelen data yapısına göre set ediyoruz
      }
    } catch (error) {
      console.error("Error fetching invited friends:", error);
    }
  };

  const shareInviteLink = () => {
    const inviteLink = `https://t.me/SimurgGameBot?start=invitedBy_${telegramId}`;
    const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
      inviteLink
    )}&text=${encodeURIComponent("Join me on SimurgGame and get rewards!")}`;
    window.open(shareUrl, "_blank");
  };

  return (
    <div
      className="min-h-screen text-white font-museo"
      style={{
        background:
          "linear-gradient(180deg, rgba(8, 39, 29, 0.56) 0%, rgba(0, 119, 79, 0.56) 50%, rgba(18, 46, 37, 0.56) 100%), #000000",
        backgroundBlendMode: "difference",
      }}
    >
      {/* Header */}
      <div className="flex justify-between items-center p-4">
        <div className="w-6 h-6" onClick={() => navigate("/")}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 512 512"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="48"
              d="M244 400L100 256l144-144M120 256h292"
            />
          </svg>
        </div>
        <span className="text-xs">{telegramId}</span>
      </div>

      {/* Invite Friends Section */}
      <div className="px-4 py-6">
        <h1 className="text-2xl font-normal text-white">Invite friends!</h1>
        <p className="mt-2 text-sm text-gray-300">
          Earn more with invited referral system, your friend and you will
          receive bonuses.
        </p>
      </div>

      {/* Invite Friends Button */}
      <div className="px-4">
        <button
          onClick={shareInviteLink}
          className="w-full px-4 py-2 text-sm text-black bg-[#D6F903] rounded-lg border border-[#D6F903] shadow-[0px_4px_0px_0px_#B7D500] transition-transform duration-150 ease-in-out active:scale-95"
        >
          Invite friend via Telegram
        </button>
      </div>

      {/* Button to Toggle Friend List */}
      <div className="">
        <div
          onClick={toggleListVisibility}
          className="flex justify-between items-center mt-6 rounded-lg cursor-pointer mx-4"
        >
          <span className="text-white text-sm ml-0">
            List of your friends ({invitedFriends.length})
          </span>
          <span className="text-white text-sm mr-0">
            Total Invited: {invitedFriends.length}
          </span>
        </div>

        {/* Friend List */}
        {isListVisible && invitedFriends.length > 0 && (
          <div className="mt-4 mx-4">
            {invitedFriends.map((friend) => (
              <div
                key={friend._id}
                className="mt-3 p-4 bg-[#1A1E00] rounded-lg flex items-center space-x-4 border border-[#D5F803]"
              >
                <div className="flex-shrink-0 w-16 h-16 bg-gray-700 rounded-sm"></div>
                <div className="flex-1">
                  <div className="flex justify-between items-center">
                    <span className="text-white text-sm">
                      User ID: {friend._id}
                    </span>
                  </div>
                  <div className="flex justify-between items-center mt-2">
                    <span className="text-xs text-gray-300">
                      First Main Test Passed:{" "}
                      {friend.firstMainTestPassed ? "Yes" : "No"}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <BottomTab activeTab="Friends" />
    </div>
  );
};

export default Friends;
