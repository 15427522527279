import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import BottomTab from "../../components/BottomTab";

const QuizDetail = () => {
  const { id } = useParams();
  const activeTab = "Home";
  const navigate = useNavigate();
  const [quizData, setQuizData] = useState(null);
  const [answers, setAnswers] = useState([]); // Cevapları tutacak state
  const [startTime, setStartTime] = useState(null); // Test başlangıç zamanı
  const [timeSpent, setTimeSpent] = useState(0); // Geçen süreyi tutmak için state
  const [quizResult, setQuizResult] = useState(null); // Quiz sonucu için state
  const [showModal, setShowModal] = useState(false); // Modal görünürlüğü

  const user = JSON.parse(localStorage.getItem("userData"));

  // Quiz verilerini çek ve başlangıç zamanını ayarla
  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const response = await axios.get(
          `https://jiicogame.com/api/v1/quiz/getQuizById/${id}`
        );
        if (response.data.success) {
          setQuizData(response.data.data);
          setStartTime(new Date()); // Test başladığında zamanı kaydet
        }
      } catch (error) {
        console.error("Failed to fetch quiz data", error);
      }
    };

    fetchQuizData();
  }, [id]);

  // Kullanıcı bir cevap seçtiğinde cevapları güncelle
  const handleAnswerSelect = (questionId, answerId) => {
    setAnswers((prevAnswers) => {
      const existingAnswerIndex = prevAnswers.findIndex(
        (answer) => answer.questionId === questionId
      );

      if (existingAnswerIndex >= 0) {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex].answerId = answerId;
        return updatedAnswers;
      } else {
        return [...prevAnswers, { questionId, answerId }];
      }
    });
  };

  // Quiz'i sonlandır ve cevapları gönder
  const handleSubmitQuiz = async () => {
    try {
      const finishTime = new Date(); // Test bitiş zamanını al
      const timeSpentInSeconds = Math.floor((finishTime - startTime) / 1000);
      setTimeSpent(timeSpentInSeconds); // Geçen süreyi state'e kaydet

      const payload = {
        quizId: id,
        userId: user._id,
        answers: answers,
        timeSpent: timeSpentInSeconds,
      };

      const response = await axios.post(
        "https://jiicogame.com/api/v1/quiz/submitQuiz",
        payload
      );

      if (response.data.success) {
        setQuizResult(response.data.data); // Quiz sonucunu kaydet
        setShowModal(true); // Quiz gönderildiğinde modalı göster
      } else {
        alert("Quiz gönderimi sırasında bir hata oluştu!");
      }
    } catch (error) {
      console.error("Failed to submit quiz", error);
      alert("Quiz gönderimi sırasında bir hata oluştu!");
    }
  };

  // Geçen süreyi dakika:saniye formatında göstermek için fonksiyon
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes} dakika ${seconds < 10 ? `0${seconds}` : seconds} saniye`;
  };

  // Tüm sorulara cevap verildi mi kontrol et
  const allQuestionsAnswered =
    quizData && answers.length === quizData.questions.length;

  return (
    <div
      className="min-h-screen flex flex-col text-white font-museo"
      style={{
        background:
          "linear-gradient(180deg, rgba(8, 39, 29, 0.56) 0%, rgba(0, 119, 79, 0.56) 50%, rgba(18, 46, 37, 0.56) 100%), #000000",
        backgroundBlendMode: "difference",
      }}
    >
      {/* Header */}
      <div className="flex-1 overflow-y-auto pb-20">
        <div className="flex justify-between items-center p-4">
          <div className="w-6 h-6" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 512 512"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="48"
                d="M244 400L100 256l144-144M120 256h292"
              />
            </svg>
          </div>
          <span className="text-xs">{user.telegramId}</span>
        </div>
        {/* Header */}

        {quizData ? (
          <>
            <div className="px-4">
              <h1 className="text-4xl font-semibold text-white">
                {quizData.quizName}
              </h1>
              <p className="text-sm text-gray-300 mt-2">
                Earn more with invited referral system, your friend and you will
                receive bonuses.
              </p>
            </div>
            <div className="px-4 mt-4">
              {quizData.questions.map((question, index) => (
                <div
                  key={question._id}
                  className="bg-[#1A1E00] border border-[#D6F903] rounded-[10px] p-4 text-white mb-4"
                >
                  <p className="text-lg font-semibold mb-4">
                    {index + 1}. {question.question}
                  </p>
                  <div className="grid grid-cols-2 gap-4">
                    {question.answers.map((answer) => (
                      <label key={answer._id} className="flex items-center">
                        <input
                          type="radio"
                          name={`answer-${question._id}`}
                          className="hidden peer"
                          onChange={() =>
                            handleAnswerSelect(question._id, answer._id)
                          }
                        />
                        <div className="w-4 h-4 rounded-full border-2 border-gray-500 peer-checked:bg-[#DBFF00]"></div>
                        <span className="ml-2">{answer.answer}</span>
                      </label>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            {/* Buton sadece tüm sorulara cevap verildiyse görünür */}
            {allQuestionsAnswered && (
              <div className="px-4 mb-4">
                <button
                  onClick={handleSubmitQuiz}
                  className="w-full h-12 bg-[#D6F903] text-black font-semibold rounded-[10px] border border-[#D6F903] shadow-[0px_4px_0px_0px_#B7D500] mt-4 active:shadow-[0px_0px_0px_0px_#B7D500] active:translate-y-1 transition-transform duration-150"
                >
                  End Quiz
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center items-center h-full">
            <p>Loading...</p>
          </div>
        )}
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-8 text-black text-center">
            <h2 className="text-2xl font-bold">Quiz Tamamlandı!</h2>
            <p className="mt-4">Toplam geçen süre: {formatTime(timeSpent)}</p>
            <p>Quiz Adı : {quizData.quizName}</p>
            <button
              onClick={() => {
                navigate(
                  `/quizresult?quizName=${quizData.quizName}&score=${quizResult.score}&isPassed=${quizResult.isPassed}&timeSpent=${timeSpent}`
                );
              }}
              className="mt-4 px-4 py-2 bg-green-500 text-white rounded"
            >
              Sonucu Gör
            </button>
          </div>
        </div>
      )}

      {/* BottomTab Sabit */}
      <div className="fixed bottom-0 left-0 right-0">
        <BottomTab activeTab={activeTab} />
      </div>
    </div>
  );
};

export default QuizDetail;
