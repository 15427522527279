// src/App.jsx
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import Friends from "./pages/Friends/Friends";
import Sectors from "./pages/Sectors/Sectors";
import Airdrop from "./pages/Airdrop/Airdrop";
import QuizList from "./pages/Quizzes/QuizList";
import TaskList from "./pages/Tasks/TaskList";
import QuizDeatil from "./pages/Quizzes/QuizDetail";
import Settings from "./pages/Settings/Settings";
import Income from "./pages/Income/Income";
import Skins from "./pages/Home/Skins";
import QuizResult from "./pages/Quizzes/QuizResult";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/minigame" element={<Navigate to="/" replace />} />
        <Route path="/friends" element={<Friends />} />
        <Route path="/sectors" element={<Sectors />} />
        <Route path="/airdrop" element={<Airdrop />} />
        <Route path="/quiz-list" element={<QuizList />} />
        <Route path="/task-list" element={<TaskList />} />
        <Route path="/quiz/:id" element={<QuizDeatil />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/income" element={<Income />} />
        <Route path="/skins" element={<Skins />} />
        <Route path="/quizresult" element={<QuizResult />} />
      </Routes>
    </Router>
  );
}

export default App;
